
      [data-page-element="CartReview"] .elCartReviewItemVariant {
      font-size: calc(var(--product-name-size) * 0.8);
font-weight: 500;
    }
[data-page-element="CartReview"] {
      width: 100%;
display: flex;
flex-direction: column;
gap: 32px;
font-family: var(--text-font-family);
overflow: hidden;
margin-left: 0px;
margin-right: 0px;
background: var(--container-color);
    }
[data-page-element="CartReview"] .elCartReviewContinueShoppingButton,
[data-page-element="CartReview"] .elCartReviewCheckoutButton {
      margin-top: 0px;
width: 100%;
    }
[data-page-element="CartReview"] .elCartReviewContinueShoppingButton .elButton {
      background: transparent;
width: 100%;
border-style: solid;
border-color: var(--continue-shopping-color);
border-width: 1px;
    }
[data-page-element="CartReview"] .elCartReviewContinueShoppingButton .elButtonText {
      font-size: 18px;
color: var(--continue-shopping-color);
    }
[data-page-element="CartReview"] .elCartReviewContinueShoppingButton .elButton:hover .elButtonText,
[data-page-element="CartReview"] .elCartReviewContinueShoppingButton .elButton.elButtonHovered .elButtonText {
      color: var(--continue-shopping-color);
    }
[data-page-element="CartReview"] .elCartReviewCheckoutButton .elButton {
      width: 100%;
background: var(--checkout-bg-color);
    }
[data-page-element="CartReview"] .elCartReviewCheckoutButton .elButtonText {
      font-size: 18px;
color: var(--checkout-text-color);
    }
[data-page-element="CartReview"] .elCartReviewCheckoutButton .elButton:hover .elButtonText,
[data-page-element="CartReview"] .elCartReviewCheckoutButton .elButton.elButtonHovered .elButtonText {
      color: var(--checkout-text-color);
    }
[data-page-element="CartReview"] .elCartReviewItems {
      display: flex;
width: 100%;
flex-direction: column;
gap: 32px;
overflow-y: auto;
margin-left: 0px;
margin-right: 0px;
padding-left: var(--internal-horizontal-padding);
padding-right: var(--internal-horizontal-padding);
    }
[data-page-element="CartReview"] .elDividerWrapper {
      width: 100%;
margin-top: 0px;
flex-basis: auto;
padding-left: 0px;
padding-right: 0px;
padding-top: 0px;
padding-bottom: 0px;
    }
[data-page-element="CartReview"] .elDivider {
      border-color: var(--divider-color);
border-top-width: var(--divider-height);
    }
[data-page-element="CartReview"] .elCartReviewButtonExtraSmall .elButton {
      padding-left: 12px;
padding-right: 12px;
padding-top: 10px;
padding-bottom: 10px;
    }
[data-page-element="CartReview"] .elCartReviewButtonExtraSmall .elButtonText {
      font-size: 13px;
    }
[data-page-element="CartReview"] .elCartReviewButtonSmall .elButton {
      padding-left: 12px;
padding-right: 12px;
padding-top: 12px;
padding-bottom: 12px;
    }
[data-page-element="CartReview"] .elCartReviewButtonSmall .elButtonText {
      font-size: 13px;
    }
[data-page-element="CartReview"] .elCartReviewButtonMedium .elButton {
      padding-left: 12px;
padding-right: 12px;
padding-top: 14px;
padding-bottom: 14px;
    }
[data-page-element="CartReview"] .elCartReviewButtonMedium .elButtonText {
      font-size: 14px;
    }
[data-page-element="CartReview"] .elCartReviewButtonLarge .elButton {
      padding-left: 14px;
padding-right: 14px;
padding-top: 16px;
padding-bottom: 16px;
    }
[data-page-element="CartReview"] .elCartReviewButtonLarge .elButtonText {
      font-size: 14px;
    }
[data-page-element="CartReview"] .elCartReviewButtonExtraLarge .elButton {
      padding-left: 16px;
padding-right: 16px;
padding-top: 18px;
padding-bottom: 18px;
    }
[data-page-element="CartReview"] .elCartReviewButtonExtraLarge .elButtonText {
      font-size: 15px;
    }
[data-page-element="CartReview"] .elCartReviewButtonsWrapper {
      display: flex;
flex-direction: column;
gap: 16px;
width: 100%;
margin-left: 0px;
margin-right: 0px;
padding-left: var(--internal-horizontal-padding);
padding-right: var(--internal-horizontal-padding);
    }
[data-page-element="CartReview"] .elCartReviewItemWrapper {
      display: flex;
gap: 24px;
width: 100%;
margin-left: 0px;
margin-right: 0px;
    }
[data-page-element="CartReview"] .elCartReviewItemRemove {
      color: #A60000;
font-size: 14px;
text-decoration: underline;
cursor: pointer;
    }
[data-page-element="CartReview"] .elCartReviewImageLink {
      width: -moz-fit-content;
      width: fit-content;
height: -moz-fit-content;
height: fit-content;
margin-left: 0px;
margin-right: 0px;
    }
[data-page-element="CartReview"] .elImageWrapper {
      margin-top: 0px;
margin-left: 0px;
margin-right: 0px;
    }
[data-page-element="CartReview"] .elImage {
      width: 105px;
height: 98px;
-o-object-fit: cover;
   object-fit: cover;
max-width: none;
    }
[data-page-element="CartReview"] .elCartReviewItem {
      display: flex;
flex-direction: column;
width: 100%;
gap: 16px;
justify-content: space-between;
margin-left: 0px;
margin-right: 0px;
    }
[data-page-element="CartReview"] .elCartReviewItemDetails {
      display: flex;
justify-content: space-between;
width: 100%;
gap: 10px;
margin-left: 0px;
margin-right: 0px;
    }
[data-page-element="CartReview"] .elCartReviewItemDetails div {
      display: flex;
flex-direction: column;
margin-left: 0px;
margin-right: 0px;
    }
[data-page-element="CartReview"] .elCartReviewItemName {
      font-size: var(--product-name-size);
font-weight: 700;
    }
      [data-page-element="CartReview"] .elCartReviewItemName a {
      color: inherit;
margin-left: 0px;
margin-right: 0px;
    }
      
    
[data-page-element="CartReview"] .elCartReviewItemPrice {
      font-size: 14px;
    }
[data-page-element="CartReview"] .elCartReviewItemActions {
      display: flex;
width: 100%;
justify-content: space-between;
align-items: center;
margin-left: 0px;
margin-right: 0px;
    }
[data-page-element="CartReview"] .elCartReviewItemQuantity {
      display: flex;
width: 85px;
height: 32px;
margin-left: 0px;
margin-right: 0px;
border-style: solid;
border-color: var(--quantity-input-color);
border-width: 1px;
    }
[data-page-element="CartReview"] .elCartReviewItemQuantityMinus,
[data-page-element="CartReview"] .elCartReviewItemQuantityPlus {
      display: flex;
align-items: center;
justify-content: center;
padding-left: 6px;
padding-right: 6px;
height: 100%;
cursor: pointer;
    }
[data-page-element="CartReview"] .elCartReviewItemQuantityPlus span,
[data-page-element="CartReview"] .elCartReviewItemQuantityMinus span {
      font-size: 16px;
color: var(--quantity-input-color);
font-family: sans-serif;
    }
[data-page-element="CartReview"] .elCartReviewItemQuantityMinus span {
      margin-top: -10px;
    }
[data-page-element="CartReview"] .elCartReviewItemQuantityInput {
      width: 40px;
height: 100%;
border: none;
outline: none;
font-family: sans-serif;
text-align: center;
color: var(--quantity-input-color);
background: transparent;
    }
[data-page-element="CartReview"] .elCartReviewEmpty {
      display: flex;
flex-direction: column;
align-items: center;
width: 100%;
padding-top: 32px;
padding-bottom: 36px;
gap: 24px;
background: rgba(0, 0, 0, 0.1);
margin-left: 0px;
margin-right: 0px;
padding-left: 0px;
padding-right: 0px;
    }
[data-page-element="CartReview"] .elCartReviewEmpty i {
      font-size: 32px;
    }
[data-page-element="CartReview"] .elCartReviewEmptyTextWrapper {
      display: flex;
flex-direction: column;
align-items: center;
width: 100%;
gap: 12px;
max-width: 320px;
text-align: center;
margin-left: 0px;
margin-right: 0px;
    }
[data-page-element="CartReview"] .elCartReviewEmptyTitle {
      font-weight: 700;
font-size: 18px;
    }
[data-page-element="CartReview"] .elCartReviewEmptyDesc {
      font-weight: 500;
font-size: 16px;
    }
[data-page-element="CartReview"] .elCartReviewSubtotal {
      margin-right: 0px;
    }
      @media (max-width: 768px) {
        
      [data-page-element="CartReview"] .elImage {
      width: 48px;
height: 48px;
    }
[data-page-element="CartReview"] .elCartReviewItem {
      gap: 8px;
    }
[data-page-element="CartReview"] .elCartReviewItemQuantity {
      height: 25px;
    }
      
    
      }
    

      [data-page-element="CartIcon"] {
      text-align: center;
    }
[data-page-element="CartIcon"] .elCartIcon {
      position: relative;
    }
      [data-page-element="CartIcon"] .elCartIcon i {
      color: black;
    }
      
    
[data-page-element="CartIcon"] .elCartIcon:before {
      position: absolute;
display: inline-flex;
font-weight: 600;
text-align: center;
content: attr(data-count);
color: white;
flex-direction: column;
border-radius: 50%;
justify-content: center;
align-items: center;
background: #E80000;
    }
[data-page-element="CartIcon"] .elCartIcon[data-count="0"]:before {
      display: none;
    }

      [data-page-element="CartIcon"][data-cart-icon-size="xs"] .elCartIcon {
      font-size: 18px;
    }
[data-page-element="CartIcon"][data-cart-icon-size="xs"] .elCartIcon:before {
      top: -3px;
right: -9px;
font-size: 10px;
height: 15px;
min-width: 15px;
padding-left: 2px;
padding-right: 2px;
padding-top: 0px;
padding-bottom: 0px;
    }
      
    

      [data-page-element="CartIcon"][data-cart-icon-size="s"] .elCartIcon {
      font-size: 20px;
    }
[data-page-element="CartIcon"][data-cart-icon-size="s"] .elCartIcon:before {
      top: -4px;
right: -10px;
font-size: 11px;
height: 17px;
min-width: 17px;
padding-left: 4px;
padding-right: 4px;
padding-top: 0px;
padding-bottom: 0px;
    }
      
    

      [data-page-element="CartIcon"][data-cart-icon-size="m"] .elCartIcon {
      font-size: 24px;
    }
[data-page-element="CartIcon"][data-cart-icon-size="m"] .elCartIcon:before {
      top: -6px;
right: -12px;
font-size: 12px;
height: 19px;
min-width: 19px;
padding-left: 6px;
padding-right: 6px;
padding-top: 0px;
padding-bottom: 0px;
    }
      
    

      [data-page-element="CartIcon"][data-cart-icon-size="l"] .elCartIcon {
      font-size: 28px;
    }
[data-page-element="CartIcon"][data-cart-icon-size="l"] .elCartIcon:before {
      top: -8px;
right: -14px;
font-size: 13px;
height: 22px;
min-width: 22px;
padding-left: 6px;
padding-right: 6px;
padding-top: 0px;
padding-bottom: 0px;
    }
      
    

      [data-page-element="CartIcon"][data-cart-icon-size="xl"] .elCartIcon {
      font-size: 32px;
    }
[data-page-element="CartIcon"][data-cart-icon-size="xl"] .elCartIcon:before {
      top: -8px;
right: -14px;
font-size: 14px;
height: 24px;
min-width: 24px;
padding-left: 6px;
padding-right: 6px;
padding-top: 0px;
padding-bottom: 0px;
    }
      
    
[data-page-element="CartIcon"].elDisabled {
      opacity: 0.5;
pointer-events: none;
    }
[data-page-element="CartIcon"].elDisabledEditor {
      opacity: 0.5;
    }
[data-page-element="CartIcon"].elHide {
      display: none;
    }
      
    

      [data-page-element="CartReviewModal"] {
      --continue-shopping-color: var(--style-link-color, #000);
--checkout-bg-color: var(--style-link-color, #000);
--checkout-text-color: #fff;
--divider-height: 1px;
--divider-color: var(--style-link-color, rgba(0, 0, 0, 0.5));
--backdrop-color: rgba(120, 116, 116, 0.50);
--container-color: var(--style-background-color, #fff);
--quantity-input-color: var(--style-link-color, #000);
--text-font-family: sans-serif;
--product-name-size: 16px;
--internal-horizontal-padding: 25px;
    }
      
    